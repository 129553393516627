import React from 'react';
import Home from './Home';
import Navbar from "./Navbar";
import "../App.css";
import Service from './Service';
function Accueil() {
  return (
    <div className='App'>
      <Navbar />
      <Home />
      <Service />
      <footer>
        <div className="bottom-details">
          <div className="bottom_text">
            <span className="copyright_text">
              Copyright &#169; 2023<a href="/">VISIO.</a>Tous droits réservés
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Accueil;