import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { Modal, Form, Button, FloatingLabel } from "react-bootstrap";
import { FaVideo } from "react-icons/fa6";
import { BsCameraReelsFill } from "react-icons/bs";
import { signOut } from "firebase/auth";
import { auth } from "../../db/firebase";
import { getDatabase, ref, onValue,push} from "firebase/database";
import Swal from "sweetalert2";
//import { createOffer, initializeListensers } from "../../db/peerConnection";

function Dashboard(props) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  const database = getDatabase();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);
  //const [meetingNames, setMeetingNames] = useState([]);
  const [userId, setUserId] = useState(localStorage.getItem('userID'));

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid);
      localStorage.setItem("userID", user.uid);
    }
  }, []);


  const createMeeting = (meetingName) => {
    push(ref(database, "meetings"), {
      meetingName: meetingName,
    })
      .then((response) => {
        Toast.fire({
          icon: "success",
          title: "Création de réunion réussie",
        });
        const SalleId=response.key;
        navigate(`/salle/${SalleId}`);
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: "Une erreur s'est produite lors de la création de la réunion",
        });
      });
  };
  const joinMeetingByName = (meetingName) => {
    const db = getDatabase();
    const meetingsRef = ref(db, 'meetings');
    onValue(meetingsRef, (snapshot) => {
      const meetings = snapshot.val();
      if (meetings) {
        const meetingKeys = Object.keys(meetings);
        const meetingKey = meetingKeys.find(key => meetings[key].meetingName === meetingName);
        if (meetingKey) {
          navigate(`/salle/${meetingKey}`);
        } else {
          alert("Aucune réunion trouvée avec ce nom.");
        }
      } else {
        alert("Aucune réunion trouvée.");
      }
    });
  }; 
  const handleSubmitCreateMeeting = (e) => {
    e.preventDefault();
    const meetingName = e.target.elements.meetingName.value;
    createMeeting(meetingName);
    setShow(false);
  };

  const handleSubmitJoinMeeting = (e) => {
    e.preventDefault();
    const meetingName = e.target.elements.meetingName.value;
    joinMeetingByName(meetingName);
    setShow2(false);
  };

  const Deconnect = () => {
    signOut(auth)
      .then(() => {
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <div className="dashboard">
      <nav
        className="navbar navbar-expand-lg fixed-top"
        style={{ boxShadow: "20px  0 20px #264a67" }}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="#">
            <span className="navbar-brand-text">
              {" "}
              <span>VISIO</span>
            </span>
          </Link>
          <form className="d-flex" role="search">
            <Link to="" className="getstarted" onClick={Deconnect}>
              Déconnexion
            </Link>
          </form>
        </div>
      </nav>
      <section>
        <div className="container">
          <h5
            style={{ marginBottom: "-20%", fontSize: "20px" }}
            className="mt-4 text-black fw-bold debut"
          >
            Sur cette page, vous avez la possibilité de gérer vos réunions de
            manière facile et pratique. Utilisez les options disponibles
            ci-dessous pour créer une nouvelle réunion ou rejoindre une réunion
            déjà existante.
          </h5>
          <div className="row">
            <div className="col-lg-6 button">
              <div className="disposition">
                <button
                  className="btn button1"
                  type="button"
                  onClick={handleShow}
                >
                  <FaVideo />
                  <br />
                  Démarrer une nouvelle réunion
                </button>
              </div>
            </div>
            <div className="col-lg-6 button">
              <div className="disposition">
                <button
                  className="btn button2"
                  type="button"
                  onClick={handleShow2}
                >
                  <BsCameraReelsFill />
                  <br />
                  Rejoindre une réunion
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <footer>
        <div className="bottom-details">
          <div className="bottom_text">
            <span className="copyright_text">
              Copyright &#169; 2023 <a href="/">VISIO.</a>Tous droits réservés
            </span>
          </div>
        </div>
      </footer>
      <Modal
        show={show}
        onHide={handleClose}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Création d'une réunion</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmitCreateMeeting}>
            <FloatingLabel
              controlId="floatingInput"
              label="Saisissez le nom de votre réunion"
              className="mb-3"
            >
              <Form.Control type="text" name="meetingName" className="input" />
            </FloatingLabel>
            <Button
              type="submit"
              style={{
                backgroundColor: "#264a67",
                border: "none",
                marginTop: "10px",
                marginLeft: "80%",
              }}
            >
              Valider
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Rejoindre une réunion</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmitJoinMeeting}>
            <FloatingLabel
              controlId="floatingInput"
              label="Nom de la réunion"
              className="mb-3"
            >
              <Form.Control type="text" name="meetingName" className="input" />
            </FloatingLabel>
            <Button
              type="submit"
              style={{
                backgroundColor: "#264a67",
                border: "none",
                marginTop: "10px",
                marginLeft: "80%",
              }}
            >
              Valider
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Dashboard;