import React from "react";
import "../components/Dashboard/Dashboard.css";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg fixed-top" style={{backgroundColor:'rgba(255, 255, 255, 0.9)'}}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <span className="navbar-brand-text">
            {" "}
            <span>VISIO</span>
            
          </span>
        </Link>
        <form className="d-flex" role="search">
          <Link to="/connexion" className="getstarted">
            Se connecter
          </Link>
        </form>
      </div>
    </nav>
  );
};

export default Navbar;
