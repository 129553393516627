import React from "react";
import BannerImage from "../Assets/picture2.jpg";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <section className="landing">
      <div className="landing-text">
        
        <p>
          Maintenez le contact, échangez et travaillez ensemble. Grâce à la
          plateforme {" "}<strong>VISIO</strong>,élargissez vos horizons au-delà des
          limites géographiques. C'est la clé du succès.
        </p>
        <a href="#url-shorten-form" className="btn btn-lg"><Link to="/inscription" className="secondary-button">
          Commencer <FiArrowRight />{" "}
        </Link></a>
      </div>
      <div className="landing-image ">
        <img src={BannerImage}

          style={{ borderRadius: "40px 999em 999em 40px" }} alt="Working Illustration" />
      </div>
    </section>
  );
};

export default Home;
