import React from "react";
import "./style.css";
import image from "../Assets/crewdle-1.jpg";

const Service = () => {
  return (
    <div>
      <section className="service" style={{backgroundColor:'#264a67'}}>
        <h3 className="titre">Etablir une connexion </h3>
        <p className="contenu">
          Il est aussi simple d'inviter des personnes que d'envoyer un lien, et
          tout le monde peut s'inscrire avant même de s'inscrire. Que vous ayez
          un ordinateur portable ou un smartphone, vous êtes prêt à participer.
        </p>
        <div>
          <img src={image} alt=""/>
        </div>
      </section>
      <section className="avantages fonction" style={{backgroundColor:'#fff'}}>
        <h2>Les avantages de notre plateforme de visioconférence</h2>
        <div className="avantages-liste">
            <div className="avantage">
                <h3>Qualité vidéo HD</h3>
                <p>Profitez de visioconférences d'une qualité vidéo haute définition pour des interactions plus claires et plus dynamiques.</p>
            </div>
            <div className="avantage">
                <h3>Facilité d'utilisation</h3>
                <p>Notre plateforme est intuitive et facile à utiliser, permettant à tous les utilisateurs de se connecter rapidement et sans complications.</p>
            </div>
            <div className="avantage">
                <h3>Partage de fichiers</h3>
                <p>Partagez des documents, des présentations et d'autres fichiers en temps réel pendant vos visioconférences pour une collaboration efficace.</p>
            </div>
        </div>
    </section>

    </div>
  );
};

export default Service;
