import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faVideo,
  faDesktop,
  faPhoneSlash,
  faVideoSlash,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import "./MeetingFooter.css";
import { Button, Modal } from "react-bootstrap";

const MeetingFooter = (props) => {
  const {
    onStartRecording,
    onStopRecording,
    isRecording,
  } = props;
  const [streamState, setStreamState] = useState({
    mic: true,
    video: false,
    screen: false,
  });
  const [showModal, setShowModal] = useState("");
  const micClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        mic: !currentState.mic,
      };
    });
  };

  const onVideoClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        video: !currentState.video,
      };
    });
  };
  const onScreenClick = () => {
    props.onScreenClick(setScreenState);
  };

  const setScreenState = (isEnabled) => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        screen: isEnabled,
      };
    });
  };
  const onLeaveClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleLeaveConfirm = () => {
    console.log("Quitter la réunion");
    window.location.href = "/dashboard"; 
  };

  useEffect(() => {
    props.onMicClick(streamState.mic);
  }, [streamState.mic]);

  useEffect(() => {
    props.onVideoClick(streamState.video);
  }, [streamState.video]);
 
  return (
    <div className="meeting-footer">
      <div
        className={"meeting-icons " + (!streamState.mic ? "active" : "")}
        onClick={micClick}
      >
        <FontAwesomeIcon
          icon={!streamState.mic ? faMicrophoneSlash : faMicrophone}
          title="Mute"
        />
      </div>
      <div
        className={"meeting-icons " + (!streamState.video ? "active" : "")}
        onClick={onVideoClick}
      >
        <FontAwesomeIcon
          icon={!streamState.video ? faVideoSlash : faVideo}
        />
      </div>
      <div
        className="meeting-icons bg-danger"
        onClick={onLeaveClick}
      >
        <FontAwesomeIcon icon={faPhoneSlash} />
      </div>
      <div
        className="meeting-icons"
        onClick={onScreenClick}
        disabled={streamState.screen}
      >
        <FontAwesomeIcon icon={faDesktop} />
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir quitter la réunion ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button style={{ backgroundColor: "#264a67", border: 'none' }} onClick={handleLeaveConfirm}>
            Quitter
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MeetingFooter;
