import React,{useState} from "react";
import "./Connexion.css";
import { NavLink, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../../db/firebase";
import Swal from "sweetalert2";
export const Inscription = () => {
    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
    const [name,setName]=useState('');
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');
    const navigate = useNavigate();
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const submit = (e) => {
        e.preventDefault()
        if (!name || !email || !password) {
            Toast.fire({
                icon: "error",
                title: "Tous les champs sont requis"
            });
          return;
        }
    
        setSubmitButtonDisabled(true);
        createUserWithEmailAndPassword(auth, email, password)
          .then(async (res) => {
            setSubmitButtonDisabled(false);
            const user = res.user;
            await updateProfile(user, {
              displayName: name,
            });
            Toast.fire({
                icon: "success",
                title: "Inscription réussie "
            });
            navigate("/connexion");
          })
          .catch((err) => {
            setSubmitButtonDisabled(false);
            Toast.fire({
                icon: "error",
                title: err + "Inscription échouée"
            });
          });
      };

    return (
        <div className="corps">
            <form className="formulaire2">
                <h3>Inscription</h3>
                <input type="text" name="Username" value={name} placeholder="Username" onChange={(e) => setName(e.target.value)}/>

                <input type="text" name="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
                <input type="password" name="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                <button type="submit" className="Login" onClick={submit} disabled={submitButtonDisabled}>S'inscrire</button>
                <p className="text-center text-white">Vous avez déjà un compte? 
                    <NavLink to="/connexion" className="connexion">
                        Se connecter
                    </NavLink>
                </p>
                
            </form>
        </div>
    )
};
export default Inscription;