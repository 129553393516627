import React, { useRef, useEffect, useState } from "react";
import MeetingFooter from "../MeetingFooter/MeetingFooter";
import Participants from "../Participants/Participants";
import "./MainScreen.css";
import { connect } from "react-redux";
import { setMainStream, updateUser } from "../store/actioncreator";


const MainScreen = (props) => {
  const participantRef = useRef(props.participants);
  //const [recordedChunks, setRecordedChunks] = useState([]);

  const onMicClick = async (micEnabled) => {
    if (props.stream) {
      props.stream.getAudioTracks()[0].enabled = micEnabled;
      props.updateUser({ audio: micEnabled });

      if (micEnabled) {
        const audioContext = new AudioContext();
        const source = audioContext.createMediaStreamSource(props.stream);
        const filter = audioContext.createBiquadFilter();
        filter.type = "lowpass";
        filter.frequency.value = 1000;
        source.connect(filter);
        filter.connect(audioContext.destination);
      }
    }
  };

  const onVideoClick = async (videoEnabled) => {
    if (props.stream) {
      props.stream.getVideoTracks()[0].enabled = videoEnabled;
      props.updateUser({ video: videoEnabled });
    }
  };

  useEffect(() => {
    participantRef.current = props.participants;
  }, [props.participants]);

  const updateStream = async (stream) => {
    for (let key in participantRef.current) {
      const sender = participantRef.current[key];
      if (sender.currentUser) continue;
      const peerConnection = sender.peerConnection
        .getSenders()
        .find((s) => (s.track ? s.track.kind === "video" : false));
      peerConnection.replaceTrack(stream.getVideoTracks()[0]);
    }
    props.setMainStream(stream);
  };

  const onScreenShareEnd = async () => {
    /* const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    }); */

    const mediaConstraints = {
      audio: {
        echoCancellation: true,
        noiseSuppression: true
      },
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        frameRate: { ideal: 30 }
      }
    };

    const optimizedStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);

    optimizedStream.getVideoTracks()[0].enabled = Object.values(
      props.currentUser
    )[0].video;

    await updateStream(optimizedStream);

    props.updateUser({ screen: false });
  };

  const onScreenClick = async () => {
    let mediaStream;
    if (navigator.mediaDevices.getDisplayMedia) {
      mediaStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
    } else {
      mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { mediaSource: "screen" },
      });
    }

    mediaStream.getVideoTracks()[0].onended = onScreenShareEnd;
    const mediaConstraints = {
      audio: {
        echoCancellation: true,
        noiseSuppression: true
      },
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        frameRate: { ideal: 30 }
      }
    };
    const optimizedStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);

    await updateStream(optimizedStream);

    props.updateUser({ screen: true });
  };

  const leaveMeeting = () => {
    if (props.stream) {
      props.stream.getTracks().forEach((track) => track.stop());
    }
    participantRef.current.forEach((participant) => {
      if (participant.peerConnection) {
        participant.peerConnection.close();
      }
    });
    window.location.href = "/dashboard";
  };

 /*  const [isRecording, setIsRecording] = useState(false);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    setIsRecording(false);
  }; */

  return (
    <div className="wrapper">
      <div className="main-screen">
        <Participants />
      </div>

      <div className="footer">
        <MeetingFooter
          onScreenClick={onScreenClick}
          onMicClick={onMicClick}
          onVideoClick={onVideoClick}
          onLeaveClick={leaveMeeting}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    participants: state.participants,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    updateUser: (user) => dispatch(updateUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
