import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import "firebase/compat/database";
var firebaseConfig = {
  apiKey: "AIzaSyB9wNerxBuvxKfdXl4tF4NXa1vHKf05Moo",
  databaseURL:"https://projet-test-e0ccd-default-rtdb.asia-southeast1.firebasedatabase.app/"
};
firebase.initializeApp(firebaseConfig);

export const db = firebase;

var firepadDB= firebase.database().ref();
const auth = getAuth();
const urlparams = new URLSearchParams(window.location.search);
const roomId = urlparams.get("id");


export  {auth,firepadDB};