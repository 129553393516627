import React from "react";
import { Navigate } from "react-router-dom";
//import { useAuth } from "./Context";
const ProtectedRoute = ({ children }) => {
  //const { user } = useAuth();
  const UserToken = localStorage.getItem('userToken');
  if (!UserToken) {
    return <Navigate to="/connexion" />;
  }
  return children;
};

export default ProtectedRoute;