import React,{useState} from "react";
import "./Connexion.css";
import { NavLink,useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../db/firebase";
import Swal from "sweetalert2";
export const Connexion = () => {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      const user = auth.currentUser;
  let userName ="";
if (user !== null) {
  userName = user.displayName;
}
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');
    const navigate = useNavigate();
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!email || !password) {
            Toast.fire({
                icon: "error",
                title: "Tous les champs sont requis"
            });
          return;
        }
    
        setSubmitButtonDisabled(true);
        signInWithEmailAndPassword(auth, email, password)
        .then(async (res) => {
          setSubmitButtonDisabled(false);
          const userToken = await res.user.getIdToken();
          localStorage.setItem('userToken', userToken);        localStorage.setItem('userName', res.user.displayName); 
          localStorage.setItem('userName', res.user.displayName); 
          navigate("/dashboard");
        })
          .catch((err) => {
            setSubmitButtonDisabled(false);
            Toast.fire({
                icon: "error",
                title: "Email ou mot de passe incorrect"
            });
          });
      };
    return (
        <div className="corps">
            <form className="formulaire">
                <h3>Connexion</h3>
                <input type="text" name="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>

                <input type="password" name="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                <button type="submit" className="Login" disabled={submitButtonDisabled} onClick={handleSubmit}>Se connecter</button>
                <p className="text-center text-white">Vous n'avez pas de compte? 
                    <NavLink to="/inscription" className="inscription">
                        S'inscrire
                    </NavLink>
                </p>
                
            </form>
        </div>
    )
};
export default Connexion;