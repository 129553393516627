import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/Context'; // Importez le contexte d'authentification
import Dashboard from './components/Dashboard/Dashbord';
import Connexion from './components/Authentification/Connexion';
import Inscription from './components/Authentification/Inscription';
import Accueil from './components/Accueil';
import Salle from './components/Salle/Salle';
import ProtectedRoute from './components/CheckAuth';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/connexion" element={<Connexion/>} />
          <Route path="/inscription" element={<Inscription/>} />        
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard/>
            </ProtectedRoute>
          } />
          <Route path="/salle/:meetingId" element={<Salle />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
